import { FC, SetStateAction, useEffect } from 'react';
import _ from 'lodash';
import { Service, ServiceParamOptionLabels, SortOptionsMap } from '../../../utils/Services';
import { DialogDropdownSingle } from '../../common/Dialog/GenericDialog';
import usePrevious from '../../../hooks/usePrevious';

type BetaSeriesSourceFieldsProps = {
    service: Service;
    fieldValues: {
        selectedMethod: string;
        pathParamsValues: any;
        bodyParamsValues: any;
        errors: { method?: string; name?: string; param?: string; bodyParams?: any; pathParams?: any };
    };
    fieldSetters: {
        setSelectedMethod: SetStateAction<any>;
        setErrors: SetStateAction<any>;
        setQueryParamsValues: SetStateAction<any>;
        setPathParamsValues: SetStateAction<any>;
        setBodyParamsValues: SetStateAction<any>;
    };
    dropdownOptions: { methodOptions: { value: string; label: string }[] };
};

const BetaSeriesSourceFields: FC<BetaSeriesSourceFieldsProps> = ({
    service,
    dropdownOptions: { methodOptions },
    fieldValues: { selectedMethod, bodyParamsValues, pathParamsValues, errors },
    fieldSetters: { setSelectedMethod, setQueryParamsValues, setPathParamsValues, setBodyParamsValues, setErrors }
}) => {
    const filterKey = 'filter.providerMetadata.betaseries.platforms.name';
    const betaSeriesDefaultType = 'series';

    const previousPathParams = usePrevious(pathParamsValues);

    useEffect(() => {
        // we must auto assign the default asset type for BetaSeries
        if (!pathParamsValues?.['type']) {
            setPathParamsValues({
                ...(pathParamsValues || {}),
                type: betaSeriesDefaultType
            });
        }

        if (!bodyParamsValues.sort) {
            const defaultSort = 'providerMetadata.betaseries.popularity[asc]';
            setBodyParamsValues((values: any) => ({ ...values, sort: defaultSort }));
        }
    }, [bodyParamsValues]);

    useEffect(() => {
        if (pathParamsValues && previousPathParams && pathParamsValues.type !== previousPathParams.type) {
            setBodyParamsValues({});
        }
    }, [pathParamsValues]);

    const renderCountry = () => {
        const countryOptions = service.methods[0].params.body?.country?.map((country: string) => ({
            value: country,
            label: _.get(ServiceParamOptionLabels, country, country)
        }));
        const countrySelect = (
            <DialogDropdownSingle
                value={countryOptions?.find((opt: any) => opt.value === bodyParamsValues?.country) || ''}
                options={countryOptions}
                placeholder={'Select Country'}
                onChange={(item: any) => {
                    setErrors(_.omit(errors, ['bodyParams', 'country']));
                    item.value ? setBodyParamsValues({ country: item.value }) : setBodyParamsValues({});
                }}
                error={errors.bodyParams?.country}
                isDisabled={!countryOptions?.length}
                withTopMargin
                clearable
            />
        );

        return <> {countrySelect} </>;
    };

    const renderSubServiceField = () => {
        const countryBasedSubService = service.methods[0].params.body?.subService?.find(
            (subService: { dependsOn: [{ key: string; values: string[] }] }) => {
                const countryDependency = subService.dependsOn.find((dependency) => dependency.key === 'country');

                if (!countryDependency) return false;

                return countryDependency.values?.includes(bodyParamsValues?.country);
            }
        );

        if (!countryBasedSubService) return null;

        const subServiceOptions = countryBasedSubService.values.map((subService: string) => ({
            value: subService,
            label: subService
        }));
        const subServiceSelect = (
            <DialogDropdownSingle
                value={subServiceOptions?.find((opt: any) => opt.value === bodyParamsValues?.[filterKey]) || ''}
                options={subServiceOptions}
                placeholder={'Select Sub-Service'}
                onChange={(item: any) => {
                    setErrors(_.omit(errors, ['bodyParams', filterKey]));
                    setBodyParamsValues((values: any) => ({ ...values, [filterKey]: item.value }));
                }}
                error={errors.bodyParams?.[filterKey]}
                isDisabled={!subServiceOptions?.length}
                withTopMargin
                clearable
            />
        );

        return <> {subServiceSelect} </>;
    };

    const renderSortField = () => {
        const sortOptions = service.methods[0].params.body?.sort?.map((sort: string) => ({
            value: sort,
            label: _.get(SortOptionsMap, sort, _.capitalize(sort))
        }));

        return (
            <DialogDropdownSingle
                value={sortOptions?.find((opt: any) => opt.value === bodyParamsValues?.sort)}
                options={sortOptions}
                placeholder={'Select Sorting'}
                onChange={(item: any) => {
                    setErrors(_.omit(errors, ['bodyParams', 'sort']));
                    setBodyParamsValues((values: any) => ({ ...values, sort: item.value }));
                }}
                error={errors.bodyParams?.sort}
                isDisabled={!sortOptions?.length}
                withTopMargin
            />
        );
    };

    return (
        <>
            <DialogDropdownSingle
                value={methodOptions.find((source) => source.value === selectedMethod) || ''}
                options={methodOptions}
                withTopMargin
                placeholder={'Select Method'}
                onChange={(item: any) => {
                    setErrors(_.omit(errors, 'method'));
                    setSelectedMethod(item.value);
                    setQueryParamsValues({});
                    setBodyParamsValues({});
                }}
                error={errors.method}
                clearable
            />
            {selectedMethod && <>{renderCountry()}</>}
            {bodyParamsValues?.country && <>{renderSubServiceField()}</>}
            {bodyParamsValues?.[filterKey] && <>{renderSortField()}</>}
        </>
    );
};

export default BetaSeriesSourceFields;
